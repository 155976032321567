<template>
    <div class="search_filter_wrap">
        <div class="search_item_wrap">
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keyup.native.enter="setFilterFunc('u_name',searchInput)"/>
                <button class="search_btn"  @click="setFilterFunc('u_name',searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <el-popover
                        popper-class="top_500"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg popup_bg_radius">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{ $t('category') }}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterCategory"
                                                       @change="setFilterFunc('uc_idx', filterCategory)">
                                        <el-checkbox v-for="data in utilityCategoryList" :label="data.uc_idx">{{ data.uc_name }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('status') }}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group  v-model="filterStatus"
                                                        @change="setFilterFunc('usest', filterStatus)">
                                        <el-checkbox :label="1">
                                            {{ $t('use_await') }}
                                        </el-checkbox>
                                        <el-checkbox :label="0">
                                            {{ $t('use_possible') }}
                                        </el-checkbox>
                                        <el-checkbox :label="2">
                                            {{ $t('use_end_already') }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('sell_status') }}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterType"
                                                       @change="setFilterFunc('sellst', filterType)">
                                        <el-checkbox :label="1">{{ $t('stay_buy') }}</el-checkbox>
                                        <el-checkbox :label="0">{{ $t('ing_buy') }}</el-checkbox>
                                        <el-checkbox :label="2">{{ $t('end_buy') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="wrapper_bottom" @click="changeFilter">
                        <button class="">{{ $t('apply2') }}</button>
                    </div>-->
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityUtil from "@/components/utility/UtilityUtil";
import util from "@/mixins/util";
import {mapState} from "vuex";

const {filterConfig} = UtilityUtil
export default {
    name: "UtilityFilterDefaultLayout",
    mixins: [UtilityUtil],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterType: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            utilityCategoryList: state => state.utilityCategory.utilityCategoryList,
        })
    },
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        /*runCategoryFilter(data) {
            if (!this.filterCategory.includes(data.uc_idx)) {
                this.filterCategory.push(data.uc_idx)
                this.setFilterFunc('uc_idx', this.filterCategory)
            } else {
                this.filterCategory.pop(data.uc_idx)
                this.setFilterFunc('uc_idx', this.filterCategory)
            }
            console.log(this.filterCategory)
        }*/
    },
    watch: {},
}
</script>

<style scoped>

</style>
